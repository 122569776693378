/* general */

* {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}


/* App.css */
body {
  margin: 0;
  padding: 0;
  background-color: #030202;
  color: #FFF;
  font-family: 'Helvetica Neue', Helvetica, 'Helvetica Condensed Bold', Arial, sans-serif; /* Ajout de la police */
}

p {
  font-weight: 200;
}

/* App.css */
.ytp-gradient-top {
  display: none !important;
}

.demo-reel-card iframe {
  width: 100%;
  height: 600px;
  object-fit: cover;

}

@media (max-width: 600px) {
  .demo-reel-card {
    height: 400px;
  }
}

/* App.css */
.demo-reel-card {
width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-reel-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}



/* app */
.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items:center ;
  justify-content: space-between;
}

.container {
  max-width: 1180px;
  width: 96%;
  height: 100%;
  padding: 20px 0;
}

.section {
  min-height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
  max-width: 100%;
  width: 96%;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .header {
    justify-content: center;
  }

  .navigation {
    margin-top: 2rem;
  }
}

.logo img {
  height: 80px; 
  object-fit: contain;
}

.navigation a {
  text-decoration: none;
  margin-left: 20px;
  color: #FFF; 
  font-weight: 200;
}

.navigation .active {
  color: #8f8f8f; 
}


/* film galery */
.film-gallery {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
}

@media (min-width: 600px) {
  .film-gallery {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (min-width: 900px) {
  .film-gallery {
    grid-template-columns: repeat(auto, minmax(300px, 1fr));
  }
}


/* FilmCard.css */
.film-card {
  cursor: pointer;
  overflow: hidden !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.5s ease;
}

.film-card:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease
}

.film-card img, 
.film-card video {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease
}

.film-card .loading {
  filter: blur(8px);
}

.film-info {
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: #FFF;
  text-align: center;
  position: absolute;
  bottom: -100%; 
  width: 100%;
  transition: bottom 0.3s ease;
}

.film-info h3, .film-info p  {
  font-size: 14px;
}

.film-card:hover .film-info {
  bottom: 0;
}

/* Alternatively, for a fade-in effect */
.film-info.hovered {
  bottom: 0;
  transition: bottom 0.3s ease, opacity 0.3s ease;
  opacity: 1;
}


/* Categories buttons styling */
.categories button {
  padding: 0.5rem .8rem;
  margin-right: 0.4rem;
  border: none;
  background-color: #333;
  color: #000;
  cursor: pointer;
}

.categories button.active {
  background-color: #acacac;
}


/* details */
.film-details {
  margin: 20px auto;
  text-align: center;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.gallery-item {
  width: calc(33.333% - 10px);
  margin-bottom: 15px;

}

@media (max-width: 768px) {
  .gallery-item {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .gallery-item {
    width: 100%;
  }
}

iframe {
  width: 100%;
  height: 250px;
}

img {
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
}

.film-credits {
  padding: 20px;
  margin-top: 20px;
}

.film-credits p {
  margin: 0;
  padding: 4px 0;
}



/* filtre */

.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.categories button {
  padding: 10px 15px;
  margin: 10px 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: all 0.3s;
}

.categories button.active {
  background-color: #000;
  color: #FFF;
}

.categories button:hover {
  background-color: #000;
  color: #FFF;
}



/* Page error  */

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.page-not-found h1 {
  font-size: 6em;
  margin: 0;
}

.page-not-found p {
  font-size: 2em;
}

.page-not-found a {
  margin-top: 20px;
  display: inline-block;
  padding: 15px;
  text-decoration: none;
  background: #000;
  color: #000;
  transition: all 0.3s;
  border: 1px solid #000;

}

.page-not-found a:hover {
  color: #000;
  background-color: #000;
}

/* contact */

.contact {
  text-align: center;
}

.contact h1 {
  color: #919191;
  font-size: 16px;
}

.contact a {
  font-size: 25px;
  font-weight: 600;
}


/* footer */

.footer {
  text-align: center;
  padding: 10px;
}

.footer p {
  font-size: 12px;
}

.film-gallery {
  display: flex;
  margin-left: -5px;
  width: auto;
  margin-bottom: 5px;
}
.film-gallery-column {
  padding-left: 0px; 

}

.film-gallery-column {
  background-clip: padding-box;
}

/* Style pour chaque carte de film */
.film-gallery-column > div {
  margin-bottom: 10px; /* Espace vertical entre les éléments */
}



/* modal */


/* Style pour la modal */
/* Style pour la modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-width: 90%;
  width: 100%;
  max-height: 90%;
  overflow: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.modal-image {
  max-width: 1400px;
  width: 100%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}

.close-modal,
.prev-image,
.next-image {
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
}

.close-modal {
  top: 10px;
  right: 10px;
  width: 34px;
  height: 34px;
}

.prev-image {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 34px;
  height: 34px;
}

.next-image {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 34px;
  height: 34px;
}









